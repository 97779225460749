export const DATA_TUTORIAL_BOOKS = [
  {
    id: 0,
    title: 'Olá, tudo bem com você?',
    text: '<p>Antes de começar, gostaríamos de explicar como funciona os nossos cadernos.</p>'
  },
  {
    id: 1,
    title: 'As disciplinas',
    text:
      '<p>Hoje na plataforma temos disponível para o acesso os cadernos de disciplinas do: <strong>Ensino Médio</strong> e <strong>Ensino Fundamental 2.</strong></p>'
  },
  {
    id: 2,
    title: 'Dentro dos cadernos',
    text:
      '<p>Ao entrar em uma disciplina você verá uma lista de assuntos, em que cada assunto apresentará <strong>um conjunto de aulas, exercícios e materiais.</strong></p><br><p>Esses conteúdos estão organizados em: <strong>Teoria, Questões Orientadas, Revisão, Questões ENEM, Questões Vestibulares e Questões Fuvest</strong>, conforme é mostrado no vídeo ao lado.</p>',
    imgs: [
      {
        src: 'pageBook.png',
        alt: 'A imagem mostra a página do contéudo de um caderno'
      }
    ]
  },
  {
    id: 3,
    title: 'Conheceu nossos cadernos?',
    text:
      '<p>Agora que já um pouco dos nossos cadernos. Que tal, dá uma olhadinha nos nossos conteúdos?</p>'
  }
];
